import { CardBodyType, CardPaddingType, CardFormatType, CardWrapperShadowType } from '@models/card';

export const cardTypes = {
    RESTRICTIVE_DEFAULT_CARD: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Medium,
        rounded: true,
        format: CardFormatType.Restrictive,
    },
    DEFAULT_FOUR_COLUMN: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Medium,
        rounded: true,
        format: CardFormatType.FourColumn,
    },
    BLOG_SMALL_SHARP_SHADOW: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Small,
        rounded: false,
        format: CardFormatType.Split,
    },
    BLOG_LONG: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Large,
        rounded: false,
        format: CardFormatType.Full,
    },
    SQUARE_CARD: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Medium,
        rounded: false,
        format: CardFormatType.Restrictive,
    },
    BLOG_FOUR_COLUMN: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Medium,
        rounded: false,
        format: CardFormatType.RestrictiveFourColumn,
    },
    BLOG_HEADER_LARGE: {
        shadowType: CardWrapperShadowType.None,
        border: false,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Medium,
        rounded: false,
        format: CardFormatType.Long,
    },
    BLOG_HEADER_SMALL: {
        shadowType: CardWrapperShadowType.None,
        border: false,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Medium,
        rounded: false,
        format: CardFormatType.LongSmall,
    },
    PRESS_ROOM_RELATED: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Default,
        cardPadding: CardPaddingType.Medium,
        rounded: false,
        format: CardFormatType.SingleBreak,
    },
    EXTRA_FEATURE_CARD: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.CTA,
        cardPadding: CardPaddingType.MediumFloatingButton,
        rounded: true,
        format: CardFormatType.Restrictive,
    },
    GEOTAB_LONG: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Layered,
        cardPadding: CardPaddingType.Large,
        rounded: true,
        format: CardFormatType.Full,
    },
    SMALL_NO_CONTENT_TILE: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.None,
        cardPadding: CardPaddingType.Tiny,
        rounded: true,
        format: CardFormatType.NoContentGalleryCard,
    },
    CONTENT_TILE_CENTERED: {
        shadowType: CardWrapperShadowType.Hover,
        border: true,
        cardBodyType: CardBodyType.Centered,
        cardPadding: CardPaddingType.Tiny,
        rounded: true,
        format: CardFormatType.GalleryTile,
    },
} as const;
