import { HeadingVariant, TextVariant } from '@web-for-marketing/react-ui';

interface CardTag {
    name: string;
    slug?: string;
}

interface CardButton {
    buttonText: string;
    buttonLink: string;
    target: string;
    buttonIcon?: JSX.Element;
    ariaLabel?: string;
}

export interface CardImage {
    imagePath?: string;
    mobilePath?: string | null;
    imageAlt?: string;
    mobileAlt?: string;
    lazyLoad?: boolean;
    overlayIcon?: React.ComponentType<{ className?: string }>;
}

export enum CardBodyType {
    Default = 'default',
    Centered = 'centered',
    Layered = 'layered',
    CTA = 'cta-alt',
    None = 'none',
}

export interface CardBase {
    shadowType: CardWrapperShadowType;
    border: boolean;
    cardPadding: CardPaddingType;
    rounded: boolean;
    format: CardFormatType;
}

export type CardBodyBase = NoneCardBody | DefaultCardBody | CTACardBody | LayeredCardBody;

export interface NoneCardBody extends CardBase {
    cardBodyType: CardBodyType.None;
    content: {
        image: CardImage;
        linkTarget?: string;
        ariaLabel?: string;
    };
}

export interface DefaultCardBody extends CardBase {
    cardBodyType: CardBodyType.Default | CardBodyType.Centered;
    content: {
        image?: CardImage;
        title: string;
        titleVariant?: TextVariant;
        body?: string;
        readMinutes?: string;
        publishedDateUtc?: string | null;
        linkTarget?: string;
    };
}

export interface CTACardBody extends CardBase {
    cardBodyType: CardBodyType.CTA;
    content: {
        image: CardImage;
        title: string;
        titleVariant?: HeadingVariant;
        body?: string;
        linkText?: string;
        linkTarget?: string;
        tags?: CardTag[];
        buttons?: CardButton[];
        ariaLabel?: string;
    };
}

export interface LayeredCardBody extends CardBase {
    cardBodyType: CardBodyType.Layered;
    content: {
        image: CardImage;
        subtitle: string;
        title: string;
        body: string;
        linkText: string;
        linkPath: string;
        linkTarget?: string;
        ariaLabel?: string;
    };
}

export enum CardPaddingType {
    Tiny = 'tiny',
    Small = 'small',
    Medium = 'medium',
    MediumFloatingButton = 'medium-floating-button',
    MediumAlt = 'medium-alt',
    Large = 'large',
}

export enum CardFormatType {
    Full = 'full',
    Split = 'split',
    Long = 'long',
    LongSmall = 'long-small',
    SingleBreak = 'single-break',
    Half = 'half',
    Tile = 'tile',
    GalleryTile = 'gallery-tile',
    GalleryCard = 'gallery-card',
    NoContentGalleryCard = 'no-content-gallery-tile',
    FourColumn = 'four-column',
    RestrictiveFourColumn = 'restrictive-four-column',
    Restrictive = 'restrictive-height',
    Default = 'default',
}

export enum CardWrapperShadowType {
    None = 'none',
    Hover = 'hover',
    Always = 'always',
}
