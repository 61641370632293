import React from 'react';
import { DangerouslySetInnerHTML } from './SectionComponents/helpers/DangerouslySetInnerHTML';
import { DefaultTextProps } from '@web-for-marketing/react-ui';

interface RichTextContentProps extends DefaultTextProps {
    content: string;
    hideLinks?: boolean;
}

export function RichTextContent({ content, ...other }: RichTextContentProps): JSX.Element {
    return <DangerouslySetInnerHTML parseLinks color='secondary' html={content} {...other} />;
}
