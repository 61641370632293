import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import { CMSLink, CMSLinkProps } from '@components/CMSLink';
import { wrapperStylePackage as classes } from './CardStyle';
import { CardWrapperShadowType, CardFormatType } from '@models/card';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';

interface BaseProps {
    hasBorder: boolean;
    shadowType: CardWrapperShadowType;
    isLink: boolean;
    lazyLoad?: boolean;
    rounded: boolean;
    reverse?: boolean;
    noHover?: boolean;
    target?: string | boolean;
    ariaLabel?: string;
    href?: string;
    cardFormat: CardFormatType;
    isHidden?: boolean;
}

type CardWrapperProps<C extends React.ElementType> = BaseProps &
    Omit<CMSLinkProps, keyof BaseProps> &
    Omit<GridProps<C>, keyof BaseProps>;

export function CardWrapper<C extends React.ElementType>({
    hasBorder,
    shadowType,
    lazyLoad,
    rounded,
    href,
    isLink,
    onClick,
    onKeyDown,
    cardFormat,
    reverse,
    children,
    target,
    noHover,
    ariaLabel,
    className,
    isHidden,
    ...other
}: CardWrapperProps<C>): JSX.Element {
    const availableOuterContainers =
        cardFormat === CardFormatType.Tile ||
        cardFormat === CardFormatType.GalleryTile ||
        cardFormat === CardFormatType.NoContentGalleryCard;
    const availableShadowTypes =
        shadowType === CardWrapperShadowType.Always ||
        shadowType === CardWrapperShadowType.Hover ||
        shadowType === CardWrapperShadowType.None;

    const mainCardStyles = [
        classes.cardWrapper,
        className,
        (isLink || href) && !noHover ? classes.linkHover : classes.noHover,
        onClick ? classes.clickable : '',
        reverse ? classes.reverse : '',
        availableShadowTypes ? classes[`shadow-${shadowType}`] : '',
        classes[`layout-${cardFormat}`] || '',
        hasBorder ? classes.border : '',
        rounded ? classes.rounded : '',
    ];

    return (
        <LazyLoadContainer
            lazyload={lazyLoad}
            data-testid='cardWrapperOuterContainer'
            css={[
                availableOuterContainers ? classes[`outerContainer-${cardFormat}`] : undefined,
                { width: '100%', height: '100%' },
            ]}
        >
            {isLink && href && !onClick ? (
                <CMSLink
                    href={href}
                    target={target}
                    css={mainCardStyles}
                    data-testid='cardWrapperLinkContainer'
                    aria-label={ariaLabel}
                    tabIndex={isHidden ? -1 : undefined}
                    {...other}
                >
                    {children}
                </CMSLink>
            ) : (
                <Grid
                    container
                    data-testid='cardWrapperContainer'
                    css={mainCardStyles}
                    onClick={onClick as GridProps['onClick']}
                    onKeyDown={onKeyDown as GridProps['onKeyDown']}
                    {...other}
                >
                    {children}
                </Grid>
            )}
        </LazyLoadContainer>
    );
}
