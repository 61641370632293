import React from 'react';
import { isDateVisibleAtom } from '@atoms/date';
import { useAtom } from 'jotai';
import { Skeleton } from '@mui/material';

export function PublicDateTime({ children }: { children: JSX.Element }): JSX.Element {
    const [isDateVisibile] = useAtom(isDateVisibleAtom);

    if (isDateVisibile) {
        return children;
    } else {
        // we need to know the size of the text to render a skeleton with the correct size.
        // the datetime text causes hydration warnings, so we supress them while we hide the mismatched text with the skeleton
        const clonedElement = React.cloneElement(children, { suppressHydrationWarning: true });

        return <Skeleton>{clonedElement}</Skeleton>;
    }
}
