import { isInternalOrBaseLink } from '@helpers/string';

export const getDangerouslySetInnerHTML = (
    html: string,
    baseUrl: string,
    parseLinks?: boolean,
    hideLinks?: boolean
): string => {
    if (parseLinks) {
        const spliHtml = html.split('<a');
        const updateArray = spliHtml.map((str, index) => {
            if (index === 0) {
                return str;
            }
            const match = str.match(/href="(.*?)"/);
            if (match) {
                let linkAttributes = '';
                if (hideLinks) {
                    linkAttributes += ' tabIndex="-1"';
                }
                if (match[1] && !isInternalOrBaseLink(match[1], baseUrl)) {
                    linkAttributes += ' target="_blank" rel="noopener noreferrer"';
                }
                return `<a${linkAttributes}${str}`;
            } else {
                return `<a${str}`;
            }
        });
        return updateArray.join('');
    }
    return html;
};

export const addScriptsToDOM = (scriptTags: string): void => {
    const domParser = new DOMParser();
    const parsedScriptTags = domParser.parseFromString(scriptTags, 'text/html').querySelectorAll('script');

    parsedScriptTags.forEach((scriptTag) => {
        const newScriptTag = document.createElement('script');
        newScriptTag.innerHTML = scriptTag.innerHTML;
        const attributes = scriptTag.attributes;
        for (let i = 0; i < attributes.length; i++) {
            const { name, value } = attributes[i];
            newScriptTag.setAttribute(name, value);
        }
        document.body.appendChild(newScriptTag);
    });
};

export const addLinksToDOM = (linkTags: string): void => {
    const domParser = new DOMParser();
    const parsedLinkTags = domParser.parseFromString(linkTags, 'text/html').querySelectorAll('link');

    parsedLinkTags.forEach((linkTag) => {
        const newLinkTag = document.createElement('link');
        newLinkTag.innerHTML = linkTag.innerHTML;
        const attributes = linkTag.attributes;
        for (let i = 0; i < attributes.length; i++) {
            const { name, value } = attributes[i];
            newLinkTag.setAttribute(name, value);
        }
        document.head.appendChild(newLinkTag);
    });
};

export const removeScriptTags = (html: string): { sanitizedHtml: string; scriptTags: string } => {
    const scriptTags: string[] = [];

    const sanitizedHtml = html.replace(
        /<script(?![^>]*<\/script>)(?:(?!\/\/)(?!\/\*)[^'"]|"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\/\/.*(?:\n)|\/\*(?:(?:.|\s))*?\*\/)*?<\/script>/gi,
        (match) => {
            scriptTags.push(match);
            return '';
        }
    );

    return { sanitizedHtml, scriptTags: scriptTags.join('') };
};

export const removeLinkTags = (html: string): { sanitizedHtml: string; linkTags: string } => {
    const linkTags: string[] = [];

    const sanitizedHtml = html.replace(/<link\b[^>]*?(?:\/>|>)/gi, (match) => {
        linkTags.push(match);
        return '';
    });

    return { sanitizedHtml, linkTags: linkTags.join('') };
};
